// third-party
import dayjs from 'dayjs';

// project imports
import { DEFAULT_DATE_FORMAT } from 'constants/settings';
import { STORAGE_DATE_FORMATTING } from 'constants/store';

const dateFormatting: Record<string, Record<string, string>> = {
    us: {
        date_kpi: 'MM/DD/YYYY',
        date_app: 'MMM DD, YYYY',
        date_kpi_abbr: 'MM/DD',
        datetime_kpi: 'MM/DD/YYYY hh:mm A',
        datetime_app: 'MMM DD, YYYY hh:mm A',
    },
    eu: {
        date_kpi: 'DD.MM.YYYY',
        date_app: 'DD MMM YYYY',
        date_kpi_abbr: 'DD.MM',
        datetime_kpi: 'DD.MMM.YYYY HH:MM',
        datetime_app: 'DD MMM YYYY HH:MM',
    },
};

export const getCurrentTime = () => dayjs().format();

export const getGreetingMessage = () => {
    const day = new Date();
    const hr = day.getHours();

    if (hr >= 0 && hr < 12) {
        return 'Good Morning';
    }
    if (hr === 12) {
        return 'Good Noon';
    }
    if (hr >= 12 && hr <= 17) {
        return 'Good Afternoon';
    }
    return 'Good Evening';
};

export const getLocaleFromStorage = (): 'us' | 'eu' => {
    const item = localStorage.getItem(STORAGE_DATE_FORMATTING);
    const locale = item ? JSON.parse(item) : DEFAULT_DATE_FORMAT;

    return locale;
};

export const getDateFormat = (key: string) => {
    const locale = getLocaleFromStorage();

    return dateFormatting[locale][key];
};

/**
 * date: string
 * type: string; <date | datetime>
 * target: string; <kpi | kpi_abbr | app>
 */
export const formatDate = (date: Date | string, type = 'date', target = 'kpi') => {
    if (!date) return null;

    const format = getDateFormat(`${type}_${target}`);

    return dayjs(date).format(format);
};

export const formatUTCDate = (date: Date | string) => dayjs.utc(date).format();

export const formatRequestDate = (date: Date | string | undefined | null) => {
    if (!date) return null;

    return dayjs(date).format('YYYY-MM-DD');
};

export const formatTime = (time: Date | string) => dayjs(time).format('HH:mm');

export const getListOfMonths = () => {
    const listOfMonth = dayjs.monthsShort().map((name, index) => ({ text: name, value: index }));

    return listOfMonth;
};

export const getListOfYears = (length = 10) => {
    const currentYear = dayjs().year();
    const listOfYear = Array.from({ length }, (v, i) => currentYear - i);

    return listOfYear;
};

export const getMonthRange = (month = 0, year = dayjs().year()) => {
    const startOfMonth = dayjs().month(month).year(year).startOf('month').format();
    const endOfMonth = dayjs().month(month).year(year).endOf('month').format();

    return [startOfMonth, endOfMonth];
};

export const getDatesInWeek = (date: Date | string) => {
    if (!date) return [];
    const startOfWeek = dayjs(date).startOf('week'); // start off week
    const numberDatesOfWeek = 7;
    return Array.from(new Array(numberDatesOfWeek)).map((val, index) => startOfWeek.add(index, 'day'));
};

export const getLastWeek = () => dayjs().startOf('week').add(-7, 'day').toDate();
