import { JSXElementConstructor, Suspense } from 'react';

// project imports
import Loader from '../Loader/Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (ChildComponent: JSXElementConstructor<any>) => (props: any) => (
    <Suspense fallback={<Loader />}>
        <ChildComponent {...props} />
    </Suspense>
);

export default Loadable;
