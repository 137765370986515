import { useEffect, useState } from 'react';

// material-ui
import Typography from '@mui/material/Typography';

// project imports
import { formatDate } from 'utils/datetime';

const DatetimeSection = () => {
    const [value, setValue] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Typography variant="body1" component="p" sx={{ display: { xs: 'none', sm: 'block' } }}>
            {formatDate(value, 'datetime', 'app')}
        </Typography>
    );
};

export default DatetimeSection;
