import axiosInstance from './axios-instance';

const fetcher = (url: string) => axiosInstance.get(url).then((res) => res.data);

export const fetcherRoleUri = (url: string, roleUri: string) =>
    axiosInstance
        .get(url, {
            baseURL: roleUri,
        })
        .then((res) => res.data);

export default fetcher;
