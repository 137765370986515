import React, { FC } from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery, Theme } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'store/constant';

const openedMixin = (theme: Theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(12)} + 1px)`,
    },
});

interface StyledDrawerProps {
    open: boolean;
}

const StyledDrawer = styled(Drawer)<StyledDrawerProps>((props) => {
    const theme: any = props.theme;
    const open = props.open;
    return {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    } as any;
});

// ==============================|| SIDEBAR DRAWER ||============================== //
interface SidebarProps {
    drawerOpen: boolean;
    drawerToggle: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
    window?: Window;
}

const Sidebar: FC<SidebarProps> = ({ drawerOpen, drawerToggle, window }) => {
    const theme: any = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <React.Fragment>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        ...(drawerOpen && {
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 135px)',
                        }),
                        ...(!drawerOpen && {
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 80px)',
                        }),
                        paddingLeft: '16px',
                        paddingRight: '16px',
                    }}
                >
                    <Box>
                        <MenuList />
                    </Box>
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </React.Fragment>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            {matchUpMd ? (
                <StyledDrawer
                    container={container}
                    variant="permanent"
                    open={drawerOpen}
                    sx={{
                        '& .MuiDrawer-paper': {
                            ...(drawerOpen && {
                                maxWidth: drawerWidth,
                            }),
                            ...theme.typography.shortShadow,
                            background: theme.palette.background.default,
                            boxShadow: '4px 8px 8px rgb(49 69 106 / 16%)',
                            color: theme.palette.text.primary,
                            borderRight: 'none',
                            borderTopRightRadius: theme.shape.borderRadius,
                            [theme.breakpoints.up('md')]: {
                                top: '80px',
                            },
                        },
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {drawer}
                </StyledDrawer>
            ) : (
                <StyledDrawer
                    container={container}
                    variant="temporary"
                    anchor="left"
                    open={drawerOpen}
                    onClose={drawerToggle}
                    sx={{
                        '& .MuiDrawer-paper': {
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none',
                            width: drawerWidth,
                        },
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {drawer}
                </StyledDrawer>
            )}
        </Box>
    );
};

export default Sidebar;
