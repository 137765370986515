import React, { useState, useRef, useEffect } from 'react';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import { IconLogout, IconChevronDown, IconChevronUp } from '@tabler/icons';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material';

// project imports
import { getGreetingMessage } from 'utils/datetime';
import { useAuth } from 'context/authContext';

import MainCard from 'components/cards/MainCard';
import Transitions from 'components/Transitions';
import { useGlobal } from 'context/globalContext';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const auth = useAuth();
    const theme: any = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { userInfo } = useGlobal();
    const customization = useSelector((state: any) => state.customization);

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);
    const handleLogout = async () => {
        auth.signout?.(() => {
            navigate('/login', { state: { from: location } });
        });
    };

    const handleClose = (event: any) => {
        if (anchorRef.current?.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen: boolean) => !prevOpen);
    };

    const prevOpen = useRef(open);

    const shortName = userInfo ? userInfo.firstName.split(' ')[0][0] + userInfo.lastName.split(' ')[0][0] : '';

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <React.Fragment>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '10px',
                    transition: 'all .2s ease-in-out',
                    border: 'none',
                    backgroundColor: theme.palette.background.paper,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        ...theme.typography.shortShadowActive,
                        background: `${theme.palette.background.paper}!important`,
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0,
                    },
                }}
                icon={
                    <Avatar
                        src={undefined}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="primary.main"
                    >
                        <Typography color="primary.dark" sx={{ fontWeight: 500 }}>
                            {shortName}
                        </Typography>
                    </Avatar>
                }
                label={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body1" color="inherit" sx={{ mr: 1 }}>
                            {`${userInfo?.firstName} ${userInfo?.lastName}`}
                        </Typography>
                        {open ? (
                            <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                        ) : (
                            <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                        )}
                    </Box>
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="default"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} content={false}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">{`${getGreetingMessage()},`}</Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {`${userInfo?.firstName} ${userInfo?.lastName}`}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="subtitle2">Role ({userInfo?.role})</Typography>
                                        </Stack>
                                    </Box>
                                    <PerfectScrollbar
                                        style={{
                                            height: '100%',
                                            maxHeight: 'calc(100vh - 250px)',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        <Box sx={{ p: 2 }}>
                                            <Divider />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%',
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5,
                                                    },
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${customization.borderRadius}px`,
                                                    }}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default ProfileSection;
