import { useState, createContext, useContext, ReactNode, FC } from 'react';

// project imports
import { STORAGE_AUTH_USER, STORAGE_AUTH_TOKEN } from 'constants/store';
import * as authAPI from 'services/auth';
import useLocalStorage from 'hooks/useLocalStorage';
import { AuthProviderState, LoginResponse, LoginForm } from 'models/auth';

export interface AuthProviderProps {
    children: ReactNode;
}

export const AuthContext = createContext<AuthProviderState>({
    token: '',
    error: '',
    loading: false,
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken] = useLocalStorage(STORAGE_AUTH_TOKEN, null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const signin = ({ email, password }: LoginForm, callback?: (response: LoginResponse) => void) => {
        setLoading(true);
        setError('');
        authAPI
            .login({ email, password })
            .then((response) => {
                if (response.error) {
                    throw response.error;
                }
                const accessToken = response.token;
                if (accessToken) {
                    setToken(accessToken);
                }

                setError('');
                callback?.(response);
            })
            .catch(({ response, ...error }: any) => {
                if (response?.data?.error?.message) {
                    setError(response.data.error.message);
                    return;
                }

                if (error) {
                    setError(error.message);
                    return;
                }
                const { errorMessage } = response.data;

                setError(errorMessage);
            })
            .finally(() => setLoading(false));
    };

    const signout = (callback: Function) => {
        setToken('');

        localStorage.removeItem(STORAGE_AUTH_USER);
        localStorage.removeItem(STORAGE_AUTH_TOKEN);
        localStorage.clear();

        callback();
    };

    const changeToken = (newToken: string) => {
        setToken(newToken);
    };

    const value: AuthProviderState = {
        token,
        error,
        signin,
        signout,
        loading,
        changeToken,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
