import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import { AppProvider } from 'context/appContext';
import { GlobalProvider } from 'context/globalContext';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'routes/AuthGuard';

const UserManagementPage = Loadable(lazy(() => import('pages/users')));
const CompanyManagementPage = Loadable(lazy(() => import('pages/companies')));
const WarehouseManagementPage = Loadable(lazy(() => import('pages/warehouses')));
const ApplicationManagementPage = Loadable(lazy(() => import('pages/applications')));
const ToolManagementPage = Loadable(lazy(() => import('pages/tools')));
const FunctionManagementPage = Loadable(lazy(() => import('pages/functions')));
const ProfileManagementPage = Loadable(lazy(() => import('pages/profiles')));
const InvitedUserPage = Loadable(lazy(() => import('pages/invited-user')));
const OperatingHoursPage = Loadable(lazy(() => import('pages/operating-hours')));
const Translations = Loadable(lazy(() => import('pages/translations')));

// utils routing
const NotFound = Loadable(lazy(() => import('pages/pages/error/NotFound')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <AppProvider>
                <GlobalProvider>
                    <MainLayout />
                </GlobalProvider>
            </AppProvider>
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <ProfileManagementPage />,
        },
        {
            path: '/users',
            element: <UserManagementPage />,
        },
        {
            path: '/invited-users',
            element: <InvitedUserPage />,
        },
        {
            path: '/companies',
            element: <CompanyManagementPage />,
        },
        {
            path: '/warehouses',
            element: <WarehouseManagementPage />,
        },
        {
            path: '/warehouses/:warehouseId/operating-hours',
            element: <OperatingHoursPage />,
        },
        {
            path: '/applications',
            element: <ApplicationManagementPage />,
        },
        {
            path: '/tools/:applicationId',
            element: <ToolManagementPage />,
        },
        {
            path: '/functions/:toolId',
            element: <FunctionManagementPage />,
        },
        {
            path: '/profiles',
            element: <ProfileManagementPage />,
        },
        {
            path: '/translations',
            element: <Translations />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ],
};

export default MainRoutes;
