import { FC, forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';
import { INavItem } from 'models/nav';
import config from 'config';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

interface NavItemProps {
    item: INavItem;
    level: number;
}

const NavItem: FC<NavItemProps> = ({ item, level }) => {
    const theme: any = useTheme();
    const dispatch = useDispatch();

    const customization = useSelector((state: any) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon = item.icon as any;
    const itemIcon = item?.icon ? <Icon stroke={1.5} size="1.3rem" /> : null;

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: string;
    } = {
        component: forwardRef<HTMLAnchorElement>((props, ref) => (
            <Link ref={ref} {...props} to={`${config.basename}${item.url}`} target={itemTarget} />
        )),
    };

    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    if (item?.eventHandler) {
        listItemProps = { component: 'div' };
    }

    const isSelected = customization.isOpen.findIndex((id: string) => id === item.id) > -1;

    const itemHandler = (id?: string) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Tooltip
            title={<>{item.title}</>}
            arrow
            placement="right"
            disableInteractive={true}
            enterDelay={customization.opened ? 1000 : 0}
            enterNextDelay={customization.opened ? 1000 : 0}
        >
            <ListItemButton
                {...listItemProps}
                disabled={item.disabled}
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    ml: level > 1 ? 1.25 : 0,
                    mr: level > 1 ? 1.25 : 0,
                    alignItems: 'flex-start',
                    backgroundColor: customization.opened ? 'inherit' : 'transparent !important',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`,
                }}
                selected={isSelected}
                onClick={() => itemHandler(item.id)}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 4 : 36 }}>{itemIcon}</ListItemIcon>
                <ListItemText
                    sx={{
                        opacity: customization.opened ? 1 : 0,
                    }}
                    primary={
                        <Typography
                            noWrap
                            variant={customization.isOpen.findIndex((id: string) => id === item.id) > -1 ? 'h5' : 'body1'}
                            color="inherit"
                        >
                            {item.title}
                        </Typography>
                    }
                    secondary={
                        item.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {item.caption}
                            </Typography>
                        )
                    }
                />
                {item.chip && (
                    <Chip
                        color={item.chip.color}
                        variant={item.chip.variant}
                        size={item.chip.size}
                        label={item.chip.label}
                        avatar={item.chip.avatar ? <Avatar>{item.chip.avatar}</Avatar> : undefined}
                    />
                )}
            </ListItemButton>
        </Tooltip>
    );
};

export default NavItem;
