import PropTypes from 'prop-types';
import { createContext, FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useApp } from 'context/appContext';
import { useCurrentUser } from 'services/current-user';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from 'services/utils/axios-instance';

export const GlobalContext = createContext<any>(null);

interface GlobalProviderProps {
    children: ReactNode;
}
export const GlobalProvider: FC<GlobalProviderProps> = ({ children }) => {
    const appState = useApp();
    const { data: userInfo } = useCurrentUser();

    const navigate = useNavigate();

    const baseUrlWithPrefix = useMemo(() => {
        switch (userInfo?.role) {
            case 'MEGA_GALACTIC':
                return `${BASE_URL}/admin`;
            case 'WAREHOUSE_ADMIN':
                return `${BASE_URL}/wh-admin`;
            default:
                return '';
        }
    }, [userInfo]);

    useEffect(() => {
        if (userInfo) {
            if (userInfo.role === 'NORMAL_USER') {
                navigate('/notfound', { replace: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    const state = {
        ...appState,
        userInfo,
        baseUrlWithPrefix,
    };

    const actions = {};

    const value = {
        ...state,
        ...actions,
    };

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobal = () => useContext(GlobalContext);

GlobalProvider.propTypes = { children: PropTypes.node };
