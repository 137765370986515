import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option routing
const AuthLogin = Loadable(lazy(() => import('pages/pages/authentication/default/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/pages/authentication/default/Register')));
const ActiveRegistration = Loadable(lazy(() => import('pages/pages/authentication/default/ActiveRegistration')));
const ForgotPassword = Loadable(lazy(() => import('pages/pages/authentication/default/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('pages/pages/authentication/default/ResetPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />,
        },
        {
            path: '/register',
            element: <AuthRegister />,
        },
        {
            path: '/active-registration/:code',
            element: <ActiveRegistration />,
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />,
        },
        {
            path: '/reset-password/:code',
            element: <ResetPassword />,
        },
    ],
};

export default AuthenticationRoutes;
