import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// project imports
import { SET_MENU } from 'store/actions';
import NavItem from '../NavItem';

// assets
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { INavItem } from 'models/nav';

const StyledWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>((props) => {
    const theme: any = props.theme;
    const open = props.open;
    return {
        ...(open && {
            ...theme.typography.shortShadow,
            borderRadius: '10px',
            marginBottom: theme.spacing(2),
        }),
    };
});

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //
interface INavCollapse {
    menu: INavItem;
    level: number;
}

const NavCollapse: FC<INavCollapse> = ({ menu, level }) => {
    const theme: any = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state: any) => state.customization);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<string | null | undefined>(null);

    const handleClick = () => {
        if (!customization.opened) {
            dispatch({ type: SET_MENU, opened: true });
        }

        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    useEffect(() => {
        if (!customization.opened) {
            setOpen(false);
            const currentIndex = document.location.pathname
                .toString()
                .split('/')
                .findIndex((id) => id === menu.id);
            if (currentIndex <= -1) {
                setSelected(null);
            }
        } else if (customization.opened && selected) {
            setOpen(true);
        }
        // eslint-disable-next-line
    }, [customization.opened]);

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === menu.id);
        if (currentIndex > -1) {
            setOpen(true);
            setSelected(menu.id);
        }
        // eslint-disable-next-line
    }, []);

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon as any;
    const menuIcon = menu.icon ? <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} /> : null;

    return (
        <StyledWrapper open={open}>
            <Tooltip
                arrow
                title={<>menu.title</>}
                placement="right"
                enterDelay={customization.opened ? 1000 : 0}
                enterNextDelay={customization.opened ? 1000 : 0}
            >
                <ListItemButton
                    sx={{
                        borderRadius: `${customization.borderRadius}px`,
                        mb: 0.5,
                        alignItems: 'flex-start',
                        backgroundColor: level > 1 ? 'inherit' : 'transparent !important',
                        py: level > 1 ? 1 : 1.25,
                        pl: `${level * 24}px`,
                    }}
                    disableRipple={!customization.opened}
                    selected={selected === menu.id}
                    onClick={handleClick}
                >
                    <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                    <ListItemText
                        sx={{
                            opacity: customization.opened ? 1 : 0,
                        }}
                        primary={
                            <Typography noWrap variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                                {menu.title}
                            </Typography>
                        }
                        secondary={
                            menu.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {menu.caption}
                                </Typography>
                            )
                        }
                    />
                    {open ? (
                        <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    ) : (
                        <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    )}
                </ListItemButton>
            </Tooltip>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </StyledWrapper>
    );
};

export default NavCollapse;
