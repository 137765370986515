export const DEFAULT_DATE_FORMAT = 'us'; // <us | eu>
export const DEFAULT_NUMBER_FORMAT = 'us'; // <us | eu>
export const DEFAULT_LANGUGE_FORMAT = 'en'; // <en | de>
export const DEFAULT_THEME = 'default'; // <en | de>

export const LOCALE_EN = 'en';
export const LOCALE_DE = 'de';

export const supportedLanguages = [
    { value: 'en', text: 'English' },
    { value: 'de', text: 'German' },
];
export const supportedDateFormat = [
    { value: 'us', text: 'US' },
    { value: 'eu', text: 'EU' },
];
export const supportedNumberFormat = [
    { value: 'us', text: 'US' },
    { value: 'eu', text: 'EU' },
];

export const DESIGN_SELECTION_ITEMS = [
    {
        text: 'Heureka Design',
        value: 'default',
    },
    {
        text: 'Night mode',
        value: 'theme1',
    },
    {
        text: 'Neon',
        value: 'theme2',
    },
    {
        text: 'Lolipop',
        value: 'theme3',
    },
    {
        text: 'Amanda',
        value: 'theme2',
    },
    {
        text: 'Rainbow',
        value: 'theme5',
    },
];
