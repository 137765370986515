import { styled } from '@mui/material/styles';

import { drawerWidth } from 'store/constant';

interface MainProps {
    open: boolean;
}

export const Main = styled('div')<MainProps>((props) => {
    const theme: any = props.theme;
    const open = props.open;
    return {
        ...theme.typography.mainContent,
        minHeight: 'calc(100vh - 85px)',

        [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 85px)',
        },
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up('md')]: {
                marginLeft: -(drawerWidth - 115),
                width: `calc(100% - ${drawerWidth}px)`,
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
                marginRight: '10px',
            },
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: '20px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
            },
        }),
    };
});
