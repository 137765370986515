import { FC, useEffect, useState } from 'react';
// material-ui
import { List, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { INavItem } from 'models/nav';
import { useGlobal } from '../../../../../context/globalContext';
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //
interface NavGroupProps {
    item: INavItem;
}
const NavGroup: FC<NavGroupProps> = ({ item }) => {
    const theme: any = useTheme();
    const { userInfo } = useGlobal();
    const [visibleItems, setVisibleItems] = useState<INavItem[]>([]);

    useEffect(() => {
        const updatedItems = item.children?.filter((item) => item.roles && item.roles.indexOf(userInfo?.role) >= 0) || [];
        setVisibleItems(updatedItems);
    }, [item.children, userInfo]);

    // menu list collapse & items
    const items = visibleItems.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <List
            subheader={
                item.title && (
                    <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                        {item.title}
                        {item.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {item.caption}
                            </Typography>
                        )}
                    </Typography>
                )
            }
        >
            {items}
        </List>
    );
};

export default NavGroup;
