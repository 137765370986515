import { UserInfo } from 'models/users';
import { HttpResponse } from 'models/api';
import useSWR from 'swr';

const useCurrentUser = () => {
    const { data, error, isValidating, mutate } = useSWR<HttpResponse<UserInfo>>(() => '/v1/current-user');

    return {
        mutate,
        isValidating,
        isError: error || data?.error,
        data: data?.data,
        isLoading: !error && !data,
    };
};

export default useCurrentUser;
