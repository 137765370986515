import { useLocation, Navigate } from 'react-router-dom';
import { FC, ReactElement } from 'react';

// project imports
import { useAuth } from 'context/authContext';

interface AuthGuardProps {
    children: ReactElement;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
    const location = useLocation();
    const auth = useAuth();

    if (!auth.token) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
};

export default AuthGuard;
