import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// project imports
import { LOCALE_DE, LOCALE_EN } from 'constants/settings';
import { getResources } from 'services/translation';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const appLocales = [LOCALE_EN, LOCALE_DE];
export const DEFAULT_LOCALE = LOCALE_EN;

const backendOptions = {
    loadPath: '{{lng}}|{{ns}}',
    request: async (
        _: any,
        url: string,
        payload: any,
        callback: (
            status: string | null,
            response: {
                data?: any;
                status: number;
            }
        ) => void
    ) => {
        try {
            const data = await getResources(LOCALE_EN);
            const formattedData = data?.reduce((accum: any, { key, text }: any) => ({ ...accum, [key]: text }), {});

            callback(null, {
                data: formattedData,
                status: 200,
            });
        } catch (e) {
            console.error('Error fetching resources:', e);
            callback(null, {
                status: 500,
            });
        }
    },
};

i18n.use(languageDetector)
    .use(backend)
    .use(initReactI18next)
    .init({
        backend: backendOptions,
        debug: false,
        defaultNS: 'translations',
        fallbackLng: DEFAULT_LOCALE,
        keySeparator: false,
        load: 'languageOnly',
        ns: ['translations'],
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            // wait: true,
            useSuspense: true,
        },
    } as any);

// i18n.on('languageChanged', (lng) => {});

export default i18n;
