import { LoginResponse } from 'models/auth';
import axiosInstance from '../utils/axios-instance';

const resource = '/v1/auth';

interface Confirm2FaCodeParams {
    code: string;
    trackingId: string;
}
export const confirm2FaCode = async ({ code, trackingId }: Confirm2FaCodeParams) => {
    const response = await axiosInstance.post(`${resource}/2fa/confirm`, { code, trackingId });

    return response.data;
};

interface Resend2FaCodeParams {
    trackingId: string;
}
export const resend2FaCode = async ({ trackingId }: Resend2FaCodeParams) => {
    const response = await axiosInstance.post(`${resource}/2fa/resend`, { trackingId });

    return response.data;
};

interface LoginParams {
    email: string;
    password: string;
}

export const login = async ({ email, password }: LoginParams): Promise<LoginResponse> => {
    const response = await axiosInstance.post(`${resource}/login`, { email, password });
    return response.data;
};

interface RefreshTokenParams {
    token: string;
}

export const refreshToken = async ({ token }: RefreshTokenParams) => {
    const response = await axiosInstance.post(`${resource}/refresh`, { token });

    return response.data;
};
