// assets

// third-party
import { IconBuildingCommunity, IconBuildingWarehouse, IconCamera, IconLanguage, IconMailOpened, IconTag, IconUser } from '@tabler/icons';

// project imports
import { INavItem } from 'models/nav';

const pages: INavItem = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'userManagement',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: IconUser,
            breadcrumbs: false,
            roles: ['MEGA_GALACTIC', 'WAREHOUSE_ADMIN'],
        },
        {
            id: 'invitedUser',
            title: 'New User',
            type: 'item',
            url: '/invited-users',
            icon: IconMailOpened,
            breadcrumbs: false,
            roles: ['MEGA_GALACTIC', 'WAREHOUSE_ADMIN'],
        },
        {
            id: 'companies',
            title: 'Companies',
            type: 'item',
            url: '/companies',
            icon: IconBuildingCommunity,
            breadcrumbs: false,
            roles: ['MEGA_GALACTIC'],
        },
        {
            id: 'warehouses',
            title: 'Warehouses',
            type: 'item',
            url: '/warehouses',
            icon: IconBuildingWarehouse,
            breadcrumbs: false,
            roles: ['MEGA_GALACTIC', 'WAREHOUSE_ADMIN'],
        },
        {
            id: 'profiles',
            title: 'Profiles',
            type: 'item',
            url: '/profiles',
            icon: IconTag,
            breadcrumbs: false,
            roles: ['MEGA_GALACTIC', 'WAREHOUSE_ADMIN'],
        },
        {
            id: 'applications',
            title: 'Applications',
            type: 'item',
            url: '/applications',
            icon: IconCamera,
            breadcrumbs: false,
            roles: ['MEGA_GALACTIC'],
        },
        {
            id: 'translations',
            title: 'Translation',
            type: 'item',
            url: '/translations',
            icon: IconLanguage,
            breadcrumbs: false,
            roles: ['MEGA_GALACTIC'],
        },
    ],
};

export default pages;
