import axios from 'axios';

// project imports
import { STORAGE_AUTH_USER, STORAGE_AUTH_TOKEN } from 'constants/store';
import { getTokenFromStorage } from 'utils/auth';

// ==============================|| INSTANCE ||============================== //

export const BASE_URL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});

// ==============================|| INTERCEPTORS ||============================== //

instance.interceptors.request.use(
    (config) => {
        const token = getTokenFromStorage();

        if (token && config?.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem(STORAGE_AUTH_USER);
            localStorage.removeItem(STORAGE_AUTH_TOKEN);
            localStorage.clear();

            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
