import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

// project imports
import config from 'config';

interface HeadProps {
    title?: string;
    description?: string;
}
const Head: FC<HeadProps> = ({ title, description }) => {
    const { pathname, origin } = window.location;
    const siteUrl = origin;
    const defaultTitle = config.seo.siteName || 'Heureka Business Solutions';
    const defaultDescription = config.seo.description || '';

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname}`,
        image: '',
    };

    return (
        <Helmet title={title} defaultTitle={seo.title} titleTemplate={`%s | ${defaultTitle}`}>
            <html lang="en" />

            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content="website" />
        </Helmet>
    );
};

export default Head;
