import { createTheme, ThemeOptions } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';
import theme1 from 'assets/scss/_theme1.module.scss';
import theme2 from 'assets/scss/_theme2.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
export interface ThemeOption {
    colors: Record<string, string>;
    heading: string;
    paper: string;
    backgroundDefault: string;
    background: string;
    darkTextPrimary: string;
    darkTextSecondary: string;
    textDark: string;
    menuSelected: string;
    menuSelectedBack: string;
    divider: string;
    customization: any;
    boxShadow?: string;
    boxShadowActive?: string;
}

export const theme = (customization: any) => {
    let color;

    switch (customization.presetColor) {
        case 'theme1':
            color = theme1;
            break;
        case 'theme2':
            color = theme2;
            break;
        case 'default':
        default:
            color = colors;
            break;
    }

    const themeOption: ThemeOption = {
        colors: color,
        heading: color.darkTextTitle,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.darkTextPrimary,
        darkTextSecondary: color.darkTextSecondary,
        textDark: color.grey900,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryLight,
        divider: color.divider,
        boxShadow: color.shadowDefault,
        boxShadowActive: color.insetShadow,
        customization,
    };

    switch (customization.navType) {
        case 'dark':
            themeOption.paper = color.darkLevel2;
            break;
        case 'light':
        default:
            themeOption.paper = color.paper;
            break;
    }

    const themeOptions: ThemeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px',
                },
            },
        },
        typography: themeTypography(themeOption) as any,
    };

    const themes = createTheme(themeOptions);
    themes.components = { ...themes.components, ...componentStyleOverrides(themeOption) } as any;

    return themes;
};

export default theme;
