import { createContext, FC, ReactNode, useContext } from 'react';

interface AppContextState {
    state: any;
    actions: any;
}
// project imports
export const AppContext = createContext<AppContextState>({
    state: {},
    actions: {},
});

interface AppProviderProps {
    children: ReactNode;
}

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
    const { Provider } = AppContext;
    const value = {
        state: {},
        actions: {},
    };

    return <Provider value={value}>{children}</Provider>;
};

export const useApp = () => useContext(AppContext);
