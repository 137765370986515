import numeral from 'numeral';

numeral.register('locale', 'de', {
    delimiters: {
        thousands: '.',
        decimal: ',',
    },
    abbreviations: { thousand: '', million: '', billion: '', trillion: '' },
    ordinal: (a: number) => a.toString(),
    currency: { symbol: '' },
});
