import { SWRConfig } from 'swr';

// routing
import Routes from 'routes';

// project imports
import fetcher from 'services/utils/fetcher';
import { AuthProvider } from 'context/authContext';
import { ManagedUIContext } from 'context/uiContext';

function App() {
    return (
        <ManagedUIContext>
            <SWRConfig
                value={{
                    refreshInterval: 0,
                    revalidateOnFocus: false,
                    shouldRetryOnError: false,
                    fetcher,
                }}
            >
                <AuthProvider>
                    <Routes />
                </AuthProvider>
            </SWRConfig>
        </ManagedUIContext>
    );
}

export default App;
