import { forwardRef, ReactNode } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 },
};

interface MainCardProps {
    border?: boolean;
    children: ReactNode;
    content?: boolean;
    contentClass?: string;
    contentSX?: object;
    darkTitle?: boolean;
    secondary?: ReactNode | string | object;
    sx?: object;
    title?: ReactNode | string | object;
}
// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef<HTMLDivElement, MainCardProps>(
    (
        { border = false, children, content = true, contentClass = '', contentSX = {}, darkTitle, secondary, sx = {}, title, ...others },
        ref
    ) => {
        const theme: any = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.primary[200] + 75,
                    boxShadow: theme.typography.shortShadow,
                    ...sx,
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
                {darkTitle && title && (
                    <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
                )}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

export default MainCard;
