import React, { FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, ButtonBase, Box, Typography, Stack } from '@mui/material';

// project imports
import { useGlobal } from 'context/globalContext';
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import DatetimeSection from './DatetimeSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
interface HeaderProps {
    handleLeftDrawerToggle: (event: MouseEvent<HTMLDivElement>) => void;
}
const Header: FC<HeaderProps> = ({ handleLeftDrawerToggle }) => {
    const theme: any = useTheme();
    const customization = useSelector((state: any) => state.customization);
    const { selectedWarehouse } = useGlobal();

    return (
        <React.Fragment>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '10px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light,
                            },
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header title */}
            <Typography variant="h2" component="h2" sx={{ m: 0, p: 0, ml: 4, display: { xs: 'none', sm: 'block' } }}>
                {customization.pageTitle}
            </Typography>

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <Stack alignItems="flex-end" mr={1}>
                <DatetimeSection />
                {selectedWarehouse && <Typography variant="h5">{selectedWarehouse.name}</Typography>}
            </Stack>

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />
        </React.Fragment>
    );
};

export default Header;
